var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"data-app":""}},[_c('v-card',[_c('v-card-title',[_c('v-row',[_c('v-col',{staticClass:"ml-4",staticStyle:{"color":"#e33354"},attrs:{"cols":"12","sm":"6","md":"6"}},[_c('h3',[_vm._v(_vm._s(_vm.cardTitle))])]),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","sm":"3","md":"3"}},[_c('div',{staticClass:"d-flex flex-row ml-auto"},[_c('v-text-field',{staticClass:"pb-5",attrs:{"append-icon":"search","label":_vm.$t('FORMS.search'),"single-line":"","hide-details":""},on:{"change":_vm.handleSearch},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-icon',{staticClass:"align-self-center mb-3 mx-3",attrs:{"color":"primary","small":""},on:{"click":function($event){_vm.options.searchTerm = null;
                _vm.searchTerm = null;
                _vm.search = null;
                _vm.getDataFromApi();}}},[_vm._v(" flaticon2-delete ")])],1)])],1)],1),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.productCollection,"options":_vm.options,"server-items-length":_vm.totalItems,"loading":_vm.loadingTable,"footer-props":_vm.getDatatableFooterProps(),"items-per-page":_vm.getDatatableItemPerPage()},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.handleClickItem},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('productModalForm',{attrs:{"modalData":_vm.modalData,"permissions":_vm.permissions,"endPoint":_vm.endPoint,"publicationCollection":_vm.publicationCollection,"priceListCollection":_vm.priceListCollection,"productCollection":_vm.productCollection},on:{"closeModalForm":_vm.handleCloseModalForm,"saveModalForm":_vm.handleSaveModalForm,"new":_vm.handleNew}}),_c('v-spacer'),_c('DeleteModalDialog',{attrs:{"dialogDelete":_vm.dialogDelete},on:{"closeDelete":_vm.handleCloseDelete,"deleteItemConfirm":_vm.handleDeleteItemConfirm}})],1)]},proxy:true},{key:"item.translated",fn:function(ref){
                var item = ref.item;
return [(item.translated)?_c('v-icon',{attrs:{"color":"green","small":""}},[_vm._v("check_circle")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [(_vm.permissionCan('delete'))?_c('v-icon',{attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")]):_vm._e()]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }