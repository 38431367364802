<template>
  <div data-app>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="6" md="6" style="color: #e33354" class="ml-4">
            <h3>{{ cardTitle }}</h3>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="3" md="3">
            <div class="d-flex flex-row ml-auto">
              <v-text-field
                v-model="search"
                append-icon="search"
                :label="$t('FORMS.search')"
                single-line
                hide-details
                @change="handleSearch"
                class="pb-5"
              ></v-text-field>
              <v-icon
                class="align-self-center mb-3 mx-3"
                color="primary"
                small
                @click="
                  options.searchTerm = null;
                  searchTerm = null;
                  search = null;
                  getDataFromApi();
                "
              >
                flaticon2-delete
              </v-icon>
            </div>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text> 
        <v-data-table
          :headers="headers"
          :items="productCollection"
          :options.sync="options"
          :server-items-length="totalItems"          
          :loading="loadingTable"
          class="elevation-1"
          :footer-props="getDatatableFooterProps()"
          :items-per-page="getDatatableItemPerPage()"
          @click:row="handleClickItem"
        >

          <template v-slot:top>
            <v-toolbar flat>
              <productModalForm
                :modalData="modalData"
                :permissions="permissions"
                :endPoint="endPoint"
                :publicationCollection="publicationCollection"
                :priceListCollection="priceListCollection"
                :productCollection="productCollection"
                @closeModalForm="handleCloseModalForm"
                @saveModalForm="handleSaveModalForm"
                @new="handleNew"
              ></productModalForm>

              <v-spacer></v-spacer>
              <DeleteModalDialog
                :dialogDelete="dialogDelete"
                @closeDelete="handleCloseDelete"
                @deleteItemConfirm="handleDeleteItemConfirm"
              >
              </DeleteModalDialog>
            </v-toolbar>
          </template>
          <template v-slot:item.translated="{ item }">
            <v-icon v-if="item.translated" color="green" small
              >check_circle</v-icon
            >
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon
              color="primary"
              v-if="permissionCan('delete')"
              small
              @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import SiteService from "@/core/services/site.service.js";

import { listModelsMixins } from "@/view/mixins/listModelsMixins.js";

import ProductModalForm, { initialFormData } from "./ProductModalForm";

import DeleteModalDialog from "@/view/components/DeleteModalDialog";

import ApiService from "@/core/services/api.service";

import i18nService from "@/core/services/i18n.service.js";

export const PERMISSION_TO = "products.property.";

export default {
  name: "Products",
  components: { ProductModalForm, DeleteModalDialog },
  mixins: [listModelsMixins],
  data() {
    return {
      cardTitle: this.$t("MENU.PRODUCTS"),
      search: "",
      routePath: "/products/products/",
      selectedLocale: i18nService.userLanguages.find((item) => {
        return item.lang == i18nService.getUserActiveLanguage();
      }).lang,
      permissionTo: PERMISSION_TO,
      permissions: JSON.parse(localStorage.permissions),
      headers: [
        /*{
          text: this.$t("TABLE.translated"),
          value: "translated",
          width: "100px",
          sortable: false,
        },*/
        {
          text: "ID",
          value: "id",
          sortable: true,
        },
        {
          text: this.$t('FORMS.rendelesi_szam'),
          value: "rendelesi_szam",
          sortable: true,
        },
        {
          text: this.$t("TABLE.name"),
          value: "name",
          sortable: false,
        },
        {
          text: this.$t("TABLE.actions"),
          value: "actions",
          sortable: false,
          width: "100px",
        },
      ],

      modalData: {
        dialog: false,
        editedId: null,
      },
      editedItem: Object.assign({}, initialFormData()),

      dialogDelete: false,

      productCollection: [],

      totalItems: 0,
      options: {
        sortBy: ["id"],
        sortDesc: [false],
        searchTerm: null
      },
    };
  },

  computed: {
    ...mapGetters([
      //"productCollection",
      "priceListCollection"
    ]),

    endPoint() {
      return "products/admin/" + SiteService.getActiveSiteId() + "/product/";
    },
  },

  watch: {
    dialogDelete(val) {
      val || this.handleCloseDelete();
    },
    "$route.params.id": {
      handler: function (id) {
        if (id) {
          this.editItem(id);
        }
      },
      deep: true,
      immediate: true,
    },
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions([
        //"fetchProduct", 
        "fetchPriceList"
      ]),
    fetchModel() {
      this.fetchPriceList();
      //return this.fetchProduct();
      this.getDataFromApi();
    },

    handleSearch() {
      if (this.search.length > 2) {
        this.options.locale = this.selectedLocale;
        this.options.searchTerm = this.search;
        this.$set(this.options, "itemsPerPage", 100);
        this.getDataFromApi();
      }
    },

    getDataFromApi() {
      this.loadingTable = true;

      ApiService.post(
        "products/admin/" + SiteService.getActiveSiteId() + "/backend_items/",
        this.options
      )
        .then((data) => { 
          this.productCollection = data.data.collection.map((item) => {
            if (item.translations) {
                let translations = item.translations[this.selectedLocale];
                if (translations) {
                  Object.keys(translations).forEach((property) => {
                    item[property] =
                      item.translations[this.selectedLocale][property];
                  });
                  item.translated = true;
                } else  {
                  for (const [key, value] of Object.entries(
                    item.translations[Object.keys(item.translations)[0]]
                  )) {
                    if ("attachments" != key) {
                      item[key] = value;
                    }
                  }
                }
            }

            return item;
          });

          this.totalItems = data.data.totalItems;
        })
        .catch((error) => { 
          if (error.response) {
            let errors = error.response.data;
            if (errors) {
              for (let field in errors.errors) {
                this.setError(field, errors.errors[field][0]);
              }
              console.log(this.messages);
            }
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
          console.log("Error!: ", error);
          // this.modalData.loading = false;
        })
        .finally(() => {
          this.loadingTable = false;
        });
    }
  },
  mounted() {
    this.loadingTable = true;
    //this.fetchModel().then(() => { this.loadingTable = false; });
    this.fetchModel();
  }
};
</script>
